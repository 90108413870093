<template>
  <footer class="tui tuim footer">
    <div class="tui tuim ui container flex centered">
      <div class="tui tuim row">
        <div class="tui ui grid middle">
          <div class="tui ui ten wide floated left column">
            <span>Copyright &copy; {{ this.$d(new Date(), 'year') }}</span>
            <nav class="tui tuim nav">
              <span
                v-text="$tc('footer.AllRightsReserved')"
                class="item"
              />

              <!-- Components disabled -->
              <a v-if="false" class="item" @click.stop.prevent="showModal('#TModalsPolicy')">
                <span v-text="$tc('footer.PrivacyPolicy')" />
              </a>
              <a v-if="false" class="item" @click.stop.prevent="showModal('#TModalsTerms')">
                <span v-text="$tc('footer.TermsOfUse')" />
              </a>
              <!-- /Components disabled -->
            </nav>
          </div>
          <div class="tui ui six wide column right aligned right floated">
            <span>Feito com <span class="heart">♥</span> por <a href="https://trackmob.com.br/" target="_blank">Trackmob</a></span>
          </div>
        </div>
      </div>
    </div>
    <!-- <t-modals-terms /> -->
    <!-- <t-modals-policy /> -->
  </footer>
</template>

<script>
// import TModalsTerms from '@/components/modals/terms'
// import TModalsPolicy from '@/components/modals/policy'

export default {
  name: 'TFooter',
  methods: {
  // showModal: id => $(id).modal('show')
  // },
  // components: {
  //   TModalsTerms,
  //   TModalsPolicy
  }
}
</script>

<style lang="scss">
@import "collect/footer";
</style>
