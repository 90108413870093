var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("footer", { staticClass: "tui tuim footer" }, [
    _c("div", { staticClass: "tui tuim ui container flex centered" }, [
      _c("div", { staticClass: "tui tuim row" }, [
        _c("div", { staticClass: "tui ui grid middle" }, [
          _c("div", { staticClass: "tui ui ten wide floated left column" }, [
            _c("span", [
              _vm._v("Copyright © " + _vm._s(this.$d(new Date(), "year")))
            ]),
            _c("nav", { staticClass: "tui tuim nav" }, [
              _c("span", {
                staticClass: "item",
                domProps: {
                  textContent: _vm._s(_vm.$tc("footer.AllRightsReserved"))
                }
              }),
              false
                ? _c(
                    "a",
                    {
                      staticClass: "item",
                      on: {
                        click: function($event) {
                          $event.stopPropagation()
                          $event.preventDefault()
                          return _vm.showModal("#TModalsPolicy")
                        }
                      }
                    },
                    [
                      _c("span", {
                        domProps: {
                          textContent: _vm._s(_vm.$tc("footer.PrivacyPolicy"))
                        }
                      })
                    ]
                  )
                : _vm._e(),
              false
                ? _c(
                    "a",
                    {
                      staticClass: "item",
                      on: {
                        click: function($event) {
                          $event.stopPropagation()
                          $event.preventDefault()
                          return _vm.showModal("#TModalsTerms")
                        }
                      }
                    },
                    [
                      _c("span", {
                        domProps: {
                          textContent: _vm._s(_vm.$tc("footer.TermsOfUse"))
                        }
                      })
                    ]
                  )
                : _vm._e()
            ])
          ]),
          _vm._m(0)
        ])
      ])
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      { staticClass: "tui ui six wide column right aligned right floated" },
      [
        _c("span", [
          _vm._v("Feito com "),
          _c("span", { staticClass: "heart" }, [_vm._v("♥")]),
          _vm._v(" por "),
          _c(
            "a",
            { attrs: { href: "https://trackmob.com.br/", target: "_blank" } },
            [_vm._v("Trackmob")]
          )
        ])
      ]
    )
  }
]
render._withStripped = true

export { render, staticRenderFns }